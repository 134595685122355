import { Injectable } from "@angular/core";
import { Action, Selector, State, StateContext, Store } from "@ngxs/store";
import {
  GetApplicationDeadlinesApplicationDeadline,
  UploadAgreementDocumentsDocumentInfo,
  UploadRequiredDocumentsRequiredDocumentInfo,
  Skill,
  SaveOpportunityRequirementsRequest,
} from "@platform-app/app/core/api/models";
import {
  OpportunityService,
  SkillsService,
} from "@platform-app/app/core/api/services";
import { tap, catchError, finalize } from "rxjs";
import {
  OpportunityOtherDocumentUploadInfo,
  OpportunityRequiredDocumentUploadInfo,
} from "@platform-app/app/main/my-opportunities/models";
import {
  MyOpportunityState,
  SetCurrentOpportunity,
} from "@platform-app/app/main/my-opportunities/my-opportunities.state";

export interface ExternshipOpportunityStateModel {
  deadlines: GetApplicationDeadlinesApplicationDeadline[] | null;
  skills: Skill[] | null;
  otherRequirementDocuments: OpportunityOtherDocumentUploadInfo[] | null;
  dataLoading: boolean;
  error: string | null;
}
export class GetDeadlines {
  static readonly type = "[Externship Opportunity] Get Deadlines";
}

export class GetSkills {
  static readonly type = "[Externship Opportunity] Get Skills";
}

export class UpdateOtherDocuments {
  static readonly type = "[Externship Opportunity] Update Other Documents";
  constructor(public payload: OpportunityOtherDocumentUploadInfo[]) {}
}

export class UploadOtherDocuments {
  static readonly type = "[Externship Opportunity] Upload Other Documents";
  constructor(
    public payload: {
      documents: OpportunityOtherDocumentUploadInfo[];
    },
  ) {}
}

export class UploadRequiredDocuments {
  static readonly type = "[Externship Opportunity] Upload Required Documents";
  constructor(
    public payload: {
      documents: OpportunityRequiredDocumentUploadInfo[];
    },
  ) {}
}

export class SaveOpportunityRequirements {
  static readonly type =
    "[Externship Opportunity] Save Opportunity Requirements";
  constructor(public payload: { body: SaveOpportunityRequirementsRequest }) {}
}

export class SetLoading {
  static readonly type = "[Externship Opportunity] Set Loading";
  constructor(public payload: { isLoading: boolean }) {}
}

@State<ExternshipOpportunityStateModel>({
  name: "ExternshipOpportunity",
  defaults: {
    deadlines: null,
    skills: null,
    otherRequirementDocuments: null,
    dataLoading: false,
    error: null,
  },
})
@Injectable()
export class ExternshipOpportunityState {
  constructor(
    private opportunityService: OpportunityService,
    private skillsService: SkillsService,
    private store: Store,
  ) {}

  @Selector()
  static otherRequirementDocuments(state: ExternshipOpportunityStateModel) {
    return state.otherRequirementDocuments;
  }

  @Selector()
  static deadlines(state: ExternshipOpportunityStateModel) {
    return state.deadlines;
  }

  @Selector()
  static skills(state: ExternshipOpportunityStateModel) {
    return state.skills;
  }

  @Selector()
  static dataLoading(state: ExternshipOpportunityStateModel) {
    return state.dataLoading;
  }

  @Action(SetLoading)
  setLoading(
    ctx: StateContext<ExternshipOpportunityStateModel>,
    action: SetLoading,
  ) {
    ctx.patchState({
      dataLoading: action.payload.isLoading,
    });
  }

  @Action(GetDeadlines)
  getDeadlines(ctx: StateContext<ExternshipOpportunityStateModel>) {
    const { deadlines } = ctx.getState();

    if (deadlines?.length) {
      return deadlines;
    }

    ctx.patchState({
      dataLoading: true,
    });

    return this.opportunityService.opportunityApplicationDeadlinesGet().pipe(
      tap((response) => {
        ctx.patchState({
          deadlines: response,
        });
      }),
      catchError((error) => {
        ctx.patchState({
          error: error,
        });
        throw error;
      }),
      finalize(() => {
        ctx.patchState({
          dataLoading: false,
        });
      }),
    );
  }

  @Action(GetSkills)
  getSkills(ctx: StateContext<ExternshipOpportunityStateModel>) {
    const { skills } = ctx.getState();

    if (skills?.length) {
      return skills;
    }

    ctx.patchState({
      dataLoading: true,
    });

    return this.skillsService.getSkills().pipe(
      tap((response) => {
        ctx.patchState({
          skills: response,
        });
      }),
      catchError((error) => {
        ctx.patchState({
          error: error,
        });
        throw error;
      }),
      finalize(() => {
        ctx.patchState({
          dataLoading: false,
        });
      }),
    );
  }

  @Action(UpdateOtherDocuments)
  updateOtherDocuments(
    ctx: StateContext<ExternshipOpportunityStateModel>,
    action: UpdateOtherDocuments,
  ) {
    ctx.patchState({
      otherRequirementDocuments: action.payload,
    });
  }

  @Action(UploadOtherDocuments)
  uploadOtherDocuments(
    ctx: StateContext<ExternshipOpportunityStateModel>,
    action: UploadOtherDocuments,
  ) {
    const opportunityId = this.store.selectSnapshot(
      MyOpportunityState.opportunityId,
    )!;

    ctx.patchState({
      dataLoading: true,
    });

    return this.opportunityService
      .opportunityOpportunityIdAgreementDocumentsPost({
        opportunityId,
        body: {
          ...mapOtherDocumentsPayload(action.payload.documents),
        },
      })
      .pipe(
        tap(() => {
          ctx.patchState({
            otherRequirementDocuments: null,
          });
        }),
        catchError((error) => {
          ctx.patchState({
            error: error,
          });
          throw error;
        }),
        finalize(() => {
          ctx.patchState({
            dataLoading: false,
          });
        }),
      );
  }

  @Action(UploadRequiredDocuments)
  uploadRequiredDocuments(
    ctx: StateContext<ExternshipOpportunityStateModel>,
    action: UploadRequiredDocuments,
  ) {
    const opportunityId = this.store.selectSnapshot(
      MyOpportunityState.opportunityId,
    )!;

    ctx.patchState({
      dataLoading: true,
    });

    return this.opportunityService
      .opportunityOpportunityIdRequiredDocumentsPost({
        opportunityId,
        body: {
          ...mapRequiredDocumentsPayload(action.payload.documents),
        },
      })
      .pipe(
        catchError((error) => {
          ctx.patchState({
            error: error,
          });
          throw error;
        }),
        finalize(() => {
          ctx.patchState({
            dataLoading: false,
          });
        }),
      );
  }

  @Action(SaveOpportunityRequirements)
  saveOpportunityRequirements(
    ctx: StateContext<ExternshipOpportunityStateModel>,
    action: SaveOpportunityRequirements,
  ) {
    ctx.patchState({
      dataLoading: true,
    });

    return this.opportunityService
      .opportunityRequirementsPut({
        body: action.payload.body,
      })
      .pipe(
        tap((response) => {
          this.store.dispatch(
            new SetCurrentOpportunity({ id: response.opportunityId }),
          );
        }),
        catchError((error) => {
          ctx.patchState({
            error: error,
          });
          throw error;
        }),
        finalize(() => {
          ctx.patchState({
            dataLoading: false,
          });
        }),
      );
  }
}

const mapOtherDocumentsPayload = (
  documents: OpportunityOtherDocumentUploadInfo[],
): {
  DocumentsInfo: {
    documentInfos: UploadAgreementDocumentsDocumentInfo[] | null;
  };
  Files: Blob[];
} => {
  const documentInfos: UploadAgreementDocumentsDocumentInfo[] = [];
  const files: Blob[] = [];

  documents.forEach((doc) => {
    documentInfos.push(doc.documentInfo!);
    files.push(doc.file!);
  });

  return {
    DocumentsInfo: { documentInfos },
    Files: files,
  };
};

const mapRequiredDocumentsPayload = (
  documents: OpportunityRequiredDocumentUploadInfo[],
): {
  DocumentsInfo: {
    documentInfos: UploadRequiredDocumentsRequiredDocumentInfo[] | null;
  };
  Files: Blob[];
} => {
  const documentInfos: UploadRequiredDocumentsRequiredDocumentInfo[] = [];
  const files: Blob[] = [];

  documents.forEach((doc) => {
    if (doc.isSelected) {
      const { file, ...docInfo } = doc;

      documentInfos.push({
        ...docInfo,
        id: docInfo.id || null,
      });
      files.push(file || new Blob([]));
    }
  });

  return {
    DocumentsInfo: { documentInfos },
    Files: files,
  };
};
