import { Injectable } from "@angular/core";
import { Action, Selector, State, StateContext, Store } from "@ngxs/store";
import {
  GetClinicOpportunitySettingsResponse,
  GetSchoolInterestSettingsResponse,
  OpportunityType,
  UpdateClinicOpportunitySettingsRequestBody,
  UpdateSchoolInterestSettingsRequestBody,
  UserGroup,
} from "@platform-app/app/core/api/models";
import {
  ClinicService,
  SchoolService,
} from "@platform-app/app/core/api/services";
import { AuthState } from "@platform-app/app/core/auth/auth.state";
import { CheckAndDispatchOnboardingStatus } from "@platform-app/app/main/organization-settings-page/profile-settings-page/organization-info.state";
import { finalize, Observable, switchMap, tap } from "rxjs";

export interface OpportunitySettingsStateModel {
  dataLoading: boolean;
  autoSaveLoading: boolean;
  manualSaveLoading: boolean;
  opportunitySettings:
    | GetSchoolInterestSettingsResponse
    | GetClinicOpportunitySettingsResponse
    | null;
  error: string | null;
}

export class GetOrganizationOpportunitySettings {
  static readonly type =
    "[Opportunity Settings] Get Organization Opportunity Settings";
}

export class AddOrganizationOpportunityType {
  static readonly type =
    "[Opportunity Settings] Add Organization Opportunity Type";
  constructor(
    public payload: {
      opportunityType: OpportunityType;
    },
  ) {}
}

export class RemoveOrganizationOpportunityType {
  static readonly type =
    "[Opportunity Settings] Remove Organization Opportunity Type";
  constructor(
    public payload: {
      opportunityType: OpportunityType;
    },
  ) {}
}

export class AddOrganizationDiscipline {
  static readonly type = "[Opportunity Settings] Add Organization Discipline";
  constructor(public payload: { disciplineId: number }) {}
}

export class RemoveOrganizationDiscipline {
  static readonly type =
    "[Opportunity Settings] Remove Organization Discipline";
  constructor(public payload: { disciplineId: number }) {}
}

export class UpdateOrganizationPreferenceDescription {
  static readonly type =
    "[Opportunity Settings] Update Organization Preference Description";
  constructor(public payload: { description: string }) {}
}

@State<OpportunitySettingsStateModel>({
  name: "OpportunitySettings",
  defaults: {
    opportunitySettings: null,
    dataLoading: false,
    autoSaveLoading: false,
    manualSaveLoading: false,
    error: null,
  },
})
@Injectable()
export class OpportunitySettingsState {
  constructor(
    private clinicService: ClinicService,
    private schoolService: SchoolService,
    private store: Store,
  ) {}

  @Selector()
  static manualSaveLoading(state: OpportunitySettingsStateModel) {
    return state.manualSaveLoading;
  }

  @Selector()
  static dataLoading(state: OpportunitySettingsStateModel) {
    return state.dataLoading;
  }

  @Selector()
  static opportunitySettings(state: OpportunitySettingsStateModel) {
    return state.opportunitySettings;
  }

  @Action(GetOrganizationOpportunitySettings)
  getOrganizationOpportunitySettings(
    ctx: StateContext<OpportunitySettingsStateModel>,
  ) {
    ctx.patchState({
      dataLoading: true,
      error: null,
    });

    return this.getOrganizationOpportunitySettingsRequest(ctx).pipe(
      finalize(() => {
        ctx.patchState({
          dataLoading: false,
        });
      }),
    );
  }

  @Action(AddOrganizationOpportunityType)
  addOrganizationOpportunityType(
    ctx: StateContext<OpportunitySettingsStateModel>,
    action: AddOrganizationOpportunityType,
  ) {
    ctx.patchState({
      autoSaveLoading: true,
      error: null,
    });

    return this.addOpportunityTypeRequest(action.payload.opportunityType).pipe(
      switchMap(() =>
        this.getOrganizationOpportunitySettingsRequest(ctx).pipe(
          switchMap(() =>
            this.store.dispatch(new CheckAndDispatchOnboardingStatus()),
          ),
        ),
      ),
      finalize(() => {
        ctx.patchState({
          autoSaveLoading: false,
        });
      }),
    );
  }

  @Action(RemoveOrganizationOpportunityType)
  removeOrganizationOpportunityType(
    ctx: StateContext<OpportunitySettingsStateModel>,
    action: RemoveOrganizationOpportunityType,
  ) {
    ctx.patchState({
      autoSaveLoading: true,
      error: null,
    });

    return this.removeOpportunityTypeRequest(
      action.payload.opportunityType,
    ).pipe(
      switchMap(() =>
        this.getOrganizationOpportunitySettingsRequest(ctx).pipe(
          switchMap(() =>
            this.store.dispatch(new CheckAndDispatchOnboardingStatus()),
          ),
        ),
      ),
      finalize(() => {
        ctx.patchState({
          autoSaveLoading: false,
        });
      }),
    );
  }

  @Action(AddOrganizationDiscipline)
  addOrganizationDiscipline(
    ctx: StateContext<OpportunitySettingsStateModel>,
    action: AddOrganizationDiscipline,
  ) {
    ctx.patchState({
      autoSaveLoading: true,
      error: null,
    });

    return this.addDisciplineRequest(action.payload.disciplineId).pipe(
      switchMap(() =>
        this.getOrganizationOpportunitySettingsRequest(ctx).pipe(
          switchMap(() =>
            this.store.dispatch(new CheckAndDispatchOnboardingStatus()),
          ),
        ),
      ),
      finalize(() => {
        ctx.patchState({
          autoSaveLoading: false,
        });
      }),
    );
  }

  @Action(RemoveOrganizationDiscipline)
  removeOrganizationDiscipline(
    ctx: StateContext<OpportunitySettingsStateModel>,
    action: RemoveOrganizationDiscipline,
  ) {
    ctx.patchState({
      autoSaveLoading: true,
      error: null,
    });

    return this.removeDisciplineRequest(action.payload.disciplineId).pipe(
      switchMap(() =>
        this.getOrganizationOpportunitySettingsRequest(ctx).pipe(
          switchMap(() =>
            this.store.dispatch(new CheckAndDispatchOnboardingStatus()),
          ),
        ),
      ),
      finalize(() => {
        ctx.patchState({
          autoSaveLoading: false,
        });
      }),
    );
  }

  @Action(UpdateOrganizationPreferenceDescription)
  updateOrganizationPreferenceDescription(
    ctx: StateContext<OpportunitySettingsStateModel>,
    action: UpdateOrganizationPreferenceDescription,
  ) {
    ctx.patchState({
      manualSaveLoading: true,
      error: null,
    });

    return this.updatePreferenceDescriptionRequest(
      action.payload.description,
    ).pipe(
      switchMap(() =>
        this.getOrganizationOpportunitySettingsRequest(ctx).pipe(
          switchMap(() =>
            this.store.dispatch(new CheckAndDispatchOnboardingStatus()),
          ),
        ),
      ),
      finalize(() => {
        ctx.patchState({
          manualSaveLoading: false,
        });
      }),
    );
  }

  private getOrganizationId(): string {
    const organizationId = this.store.selectSnapshot(AuthState.user)
      ?.organization?.id;
    if (!organizationId) {
      throw new Error("Current User does not have Organization id");
    }
    return organizationId;
  }

  private getOrganizationOpportunitySettingsRequest(
    ctx: StateContext<OpportunitySettingsStateModel>,
  ): Observable<
    GetSchoolInterestSettingsResponse | GetClinicOpportunitySettingsResponse
  > {
    const currentRole = this.store.selectSnapshot(AuthState.user)?.roleGroup;
    const organizationId = this.getOrganizationId();

    let settingsRequest: Observable<
      GetSchoolInterestSettingsResponse | GetClinicOpportunitySettingsResponse
    >;
    if (currentRole === UserGroup.SchoolUser) {
      settingsRequest = this.schoolService.schoolSchoolIdInterestSettingsGet({
        schoolId: organizationId,
      });
    } else if (currentRole === UserGroup.ClinicUser) {
      settingsRequest = this.clinicService.clinicClinicIdOpportunitySettingsGet(
        {
          clinicId: organizationId,
        },
      );
    } else {
      throw new Error("Invalid role.");
    }

    return settingsRequest.pipe(
      tap((response) => {
        ctx.patchState({
          opportunitySettings: response,
        });
      }),
    );
  }

  private addOpportunityTypeRequest(
    opportunityType: OpportunityType,
  ): Observable<void> {
    const currentRole = this.store.selectSnapshot(AuthState.user)?.roleGroup;
    const organizationId = this.getOrganizationId();

    if (currentRole === UserGroup.SchoolUser) {
      return this.schoolService.schoolSchoolIdOpportunityTypeOpportunityTypePost(
        {
          schoolId: organizationId,
          opportunityType: opportunityType,
        },
      );
    } else if (currentRole === UserGroup.ClinicUser) {
      return this.clinicService.clinicClinicIdOpportunityTypeOpportunityTypePost(
        {
          clinicId: organizationId,
          opportunityType: opportunityType,
        },
      );
    } else {
      throw new Error("Invalid role.");
    }
  }

  private removeOpportunityTypeRequest(
    opportunityType: OpportunityType,
  ): Observable<void> {
    const currentRole = this.store.selectSnapshot(AuthState.user)?.roleGroup;
    const organizationId = this.getOrganizationId();

    if (currentRole === UserGroup.SchoolUser) {
      return this.schoolService.schoolSchoolIdOpportunityTypeOpportunityTypeDelete(
        {
          schoolId: organizationId,
          opportunityType: opportunityType,
        },
      );
    } else if (currentRole === UserGroup.ClinicUser) {
      return this.clinicService.clinicClinicIdOpportunityTypeOpportunityTypeDelete(
        {
          clinicId: organizationId,
          opportunityType: opportunityType,
        },
      );
    } else {
      throw new Error("Invalid role.");
    }
  }

  private addDisciplineRequest(disciplineId: number): Observable<void> {
    const currentRole = this.store.selectSnapshot(AuthState.user)?.roleGroup;
    const organizationId = this.getOrganizationId();

    if (currentRole === UserGroup.SchoolUser) {
      return this.schoolService.schoolSchoolIdOpportunityDisciplineDisciplineIdPost(
        {
          schoolId: organizationId,
          disciplineId: disciplineId,
        },
      );
    } else if (currentRole === UserGroup.ClinicUser) {
      return this.clinicService.clinicClinicIdOpportunityDisciplineDisciplineIdPost(
        {
          clinicId: organizationId,
          disciplineId: disciplineId,
        },
      );
    } else {
      throw new Error("Invalid role.");
    }
  }

  private removeDisciplineRequest(disciplineId: number): Observable<void> {
    const currentRole = this.store.selectSnapshot(AuthState.user)?.roleGroup;
    const organizationId = this.getOrganizationId();

    if (currentRole === UserGroup.SchoolUser) {
      return this.schoolService.schoolSchoolIdOpportunityDisciplineDisciplineIdDelete(
        {
          schoolId: organizationId,
          disciplineId: disciplineId,
        },
      );
    } else if (currentRole === UserGroup.ClinicUser) {
      return this.clinicService.clinicClinicIdOpportunityDisciplineDisciplineIdDelete(
        {
          clinicId: organizationId,
          disciplineId: disciplineId,
        },
      );
    } else {
      throw new Error("Invalid role.");
    }
  }

  private updatePreferenceDescriptionRequest(
    description: string,
  ): Observable<void> {
    const currentRole = this.store.selectSnapshot(AuthState.user)?.roleGroup;
    const organizationId = this.getOrganizationId();

    if (currentRole === UserGroup.SchoolUser) {
      return this.schoolService.schoolSchoolIdPreferenceDescriptionPut({
        schoolId: organizationId,
        body: {
          preferenceDescription: description,
        } as UpdateSchoolInterestSettingsRequestBody,
      });
    } else if (currentRole === UserGroup.ClinicUser) {
      return this.clinicService.clinicClinicIdPreferenceDescriptionPut({
        clinicId: organizationId,
        body: {
          preferenceDescription: description,
        } as UpdateClinicOpportunitySettingsRequestBody,
      });
    } else {
      throw new Error("Invalid role.");
    }
  }
}
