import { Injectable } from "@angular/core";
import { Action, Selector, State, StateContext, Store } from "@ngxs/store";
import { finalize, Observable, switchMap, tap } from "rxjs";
import {
  CitizenshipSectionModel,
  EducationLevelSectionModel,
  LocationSectionModel,
  NameSectionModel,
  YearsOfExperienceSectionModel,
} from "./shared/models";
import {
  AuthState,
  UpdateUserPhoto,
} from "@platform-app/app/core/auth/auth.state";
import {
  StudentService,
  UserProfileService,
} from "@platform-app/app/core/api/services";
import {
  AddCertificationRequest,
  AddEducationRequest,
  AddExperienceRequest,
  AddLicenseRequest,
  GetStudentProgramPreferencesResponse,
  GetStudentStudentDetails,
  GetUserProfileUserProfileDetails,
  Language,
  UpdateCertificationRequest,
  UpdateEducationRequest,
  UpdateExperienceRequest,
  UpdateLicenseRequest,
  UpdateStudentProgramPreferencesRequest,
  UserGroup,
} from "@platform-app/app/core/api/models";
import { SalaryExpectationsSectionModel } from "@platform-app/app/main/user-profile/general-info/salary-expectations-section.component";
import { AnalyticsService } from "@platform-app/app/core/analytics/analytics.service";

export interface UserProfileStateModel {
  profile: GetUserProfileUserProfileDetails | null;
  student: GetStudentStudentDetails | null;
  programPreferences: GetStudentProgramPreferencesResponse | null;
  isProfileLoading: boolean;
  updatePhotoLoading: boolean;
  updateEducationLoading: boolean;
  updateExperienceLoading: boolean;
  updatePreferencesLoading: boolean;
  updateCertificationLicenseLoading: boolean;
  error: null;
}

export class GetUserProfile {
  static readonly type = "[User Profile] Get Student Details";
  constructor(public payload: { userProfileId: string }) {}
}

export class GetStudentProgramPreferences {
  static readonly type = "[User Profile] Get Student Program Preferences";
}

export class UpdateName {
  static readonly type = "[User Profile] Update Name";
  constructor(public payload: { name: NameSectionModel }) {}
}

export class UpdateHeadline {
  static readonly type = "[User Profile] Update Headline";
  constructor(public payload: { headline: string }) {}
}

export class UpdateBackgroundCheckPreferences {
  static readonly type =
    "[User Profile] Update Open to Background Check Preferences";
  constructor(public payload: { openToBackgroundCheck: boolean }) {}
}

export class UpdateDrugCheckPreferences {
  static readonly type = "[User Profile] Update Open to Drug Check Preferences";
  constructor(public payload: { openToDrugCheck: boolean }) {}
}

export class UpdateLocation {
  static readonly type = "[User Profile] Update Location";
  constructor(public payload: { location: LocationSectionModel }) {}
}

export class UpdateRelocationPreference {
  static readonly type = "[User Profile] Update Relocation Preference";
  constructor(public payload: { openToRelocate: boolean }) {}
}

export class UpdateCitizenship {
  static readonly type = "[User Profile] Update Citizenship";
  constructor(public payload: { citizenship: CitizenshipSectionModel }) {}
}

export class UpdateEducationLevel {
  static readonly type = "[User Profile] Update Education Level";
  constructor(public payload: { educationLevel: EducationLevelSectionModel }) {}
}

export class UpdateYearsOfExperience {
  static readonly type = "[User Profile] Update Years Of Experience";
  constructor(
    public payload: { yearsOfExperience: YearsOfExperienceSectionModel },
  ) {}
}

export class UpdateSalaryExpectations {
  static readonly type = "[User Profile] Update Salary Expectations";
  constructor(
    public payload: { salaryExpectations: SalaryExpectationsSectionModel },
  ) {}
}

export class UpdateLanguages {
  static readonly type = "[User Profile] Update Languages";
  constructor(public payload: { languages: Language[] }) {}
}

export class UpdatePhoneNumber {
  static readonly type = "[User Profile] Update Phone Number";
  constructor(public payload: { phoneNumber: string | null }) {}
}

export class UpdateBirthDate {
  static readonly type = "[User Profile] Update Birth Date";
  constructor(public payload: { birthDate: Date | null }) {}
}

export class UpdateStudentPhoto {
  static readonly type = "[User Profile] Update Student Photo";
  constructor(
    public payload: {
      originalPhoto: Blob;
      croppedPhoto: Blob;
    },
  ) {}
}

export class UpdateEmergencyContact {
  static readonly type = "[User Profile] Update Emergency Contact";
  constructor(public payload: { emergencyContactInfo: string | null }) {}
}

export class AddEducation {
  static readonly type = "[User Profile] Add Student Education";
  constructor(public payload: { education: AddEducationRequest }) {}
}

export class UpdateEducation {
  static readonly type = "[User Profile] Update Student Education";
  constructor(
    public payload: {
      educationId: string;
      education: UpdateEducationRequest;
    },
  ) {}
}

export class DeleteEducation {
  static readonly type = "[User Profile] Delete Student Education";
  constructor(
    public payload: {
      educationId: string;
    },
  ) {}
}

export class AddExperience {
  static readonly type = "[User Profile] Add Student Experience";
  constructor(public payload: { experience: AddExperienceRequest }) {}
}

export class UpdateExperience {
  static readonly type = "[User Profile] Update Student Experience";
  constructor(
    public payload: {
      experienceId: string;
      experience: UpdateExperienceRequest;
    },
  ) {}
}

export class DeleteExperience {
  static readonly type = "[User Profile] Delete Student Experience";
  constructor(
    public payload: {
      experienceId: string;
    },
  ) {}
}

export class UpdateStudentProgramPreferences {
  static readonly type = "[User Profile] Update Student Program Preferences";
  constructor(
    public payload: {
      preferences: UpdateStudentProgramPreferencesRequest;
    },
  ) {}
}

export class ResetStudentPreferences {
  static readonly type = "[User Profile] Reset Student Preferences";
}

export class AddCertification {
  static readonly type = "[User Profile] Add Student Certification";
  constructor(public payload: { certification: AddCertificationRequest }) {}
}

export class UpdateCertification {
  static readonly type = "[User Profile] Update Student Certification";
  constructor(
    public payload: {
      certificationId: string;
      certification: UpdateCertificationRequest;
    },
  ) {}
}

export class SaveLicense {
  static readonly type = "[User Profile] Update Student License";
  constructor(
    public payload: {
      license: UpdateLicenseRequest | AddLicenseRequest;
      licenseId?: string;
    },
  ) {}
}

export class DeleteCertification {
  static readonly type = "[User Profile] Delete Student Certification";
  constructor(public payload: { id: string }) {}
}

export class DeleteLicense {
  static readonly type = "[User Profile] Delete Student License";
  constructor(public payload: { id: string }) {}
}

@State<UserProfileStateModel>({
  name: "UserProfile",
  defaults: {
    profile: null,
    student: null,
    programPreferences: null,
    isProfileLoading: false,
    updatePhotoLoading: false,
    updateEducationLoading: false,
    updateExperienceLoading: false,
    updatePreferencesLoading: false,
    updateCertificationLicenseLoading: false,
    error: null,
  },
})
@Injectable()
export class ProfileState {
  constructor(
    private readonly studentService: StudentService,
    private readonly userProfileService: UserProfileService,
    private readonly store: Store,
    private readonly analyticsService: AnalyticsService,
  ) {}

  @Selector()
  static userProfile(state: UserProfileStateModel) {
    return state.profile;
  }

  @Selector()
  static student(state: UserProfileStateModel) {
    return state.student;
  }

  @Selector()
  static programPreferences(state: UserProfileStateModel) {
    return state.programPreferences;
  }

  @Selector()
  static studentLoading(state: UserProfileStateModel) {
    return state.isProfileLoading;
  }

  @Selector()
  static updatePhotoLoading(state: UserProfileStateModel) {
    return state.updatePhotoLoading;
  }

  @Selector()
  static updateEducationLoading(state: UserProfileStateModel) {
    return state.updateEducationLoading;
  }

  @Selector()
  static updateExperienceLoading(state: UserProfileStateModel) {
    return state.updateExperienceLoading;
  }

  @Selector()
  static updatePreferencesLoading(state: UserProfileStateModel) {
    return state.updatePreferencesLoading;
  }

  @Selector()
  static updateCertificationLicenseLoading(state: UserProfileStateModel) {
    return state.updateCertificationLicenseLoading;
  }

  @Action(GetUserProfile)
  getUserProfile(
    ctx: StateContext<UserProfileStateModel>,
    action: GetUserProfile,
  ) {
    ctx.patchState({
      isProfileLoading: true,
      profile: null,
      student: null,
    });

    return this.getUserProfileRequest(ctx, action.payload.userProfileId).pipe(
      finalize(() => {
        ctx.patchState({
          isProfileLoading: false,
        });
      }),
    );
  }

  @Action(GetStudentProgramPreferences)
  getStudentProgramPreferences(ctx: StateContext<UserProfileStateModel>) {
    const { id } = ctx.getState().profile!;

    return this.studentService
      .studentIdProfileProgramPreferencesGet({ id: id })
      .pipe(
        tap((response) => {
          ctx.patchState({
            programPreferences: response,
          });
        }),
      );
  }

  @Action(UpdateName)
  updateName(ctx: StateContext<UserProfileStateModel>, action: UpdateName) {
    const { id } = ctx.getState().profile!;

    return this.userProfileService
      .updateName({
        id: id,
        body: {
          firstName: action.payload.name.firstName,
          lastName: action.payload.name.lastName,
        },
      })
      .pipe(
        tap(() => {
          ctx.patchState({
            profile: {
              ...ctx.getState().profile!,
              firstName: action.payload.name.firstName,
              lastName: action.payload.name.lastName,
            },
          });
        }),
      );
  }

  @Action(UpdateHeadline)
  updateHeadline(
    ctx: StateContext<UserProfileStateModel>,
    action: UpdateHeadline,
  ) {
    const { id } = ctx.getState().profile!;

    return this.userProfileService
      .updateHeadline({
        id: id,
        body: { headline: action.payload.headline },
      })
      .pipe(
        tap(() => {
          this.analyticsService.trackProfile({ headline: true }, id);
          ctx.patchState({
            profile: {
              ...ctx.getState().profile!,
              headline: action.payload.headline,
            },
          });
        }),
      );
  }

  @Action(UpdateBackgroundCheckPreferences)
  updateChecksPreferences(
    ctx: StateContext<UserProfileStateModel>,
    action: UpdateBackgroundCheckPreferences,
  ) {
    const { id, openToDrugCheck } = ctx.getState().student!;

    return this.studentService
      .studentIdProfileChecksPreferencesPut({
        id: id,
        body: {
          openToBackgroundCheck: action.payload.openToBackgroundCheck,
          openToDrugCheck: openToDrugCheck,
        },
      })
      .pipe(
        tap(() => {
          this.analyticsService.trackProfile(
            { openToBackgroundCheck: action.payload.openToBackgroundCheck },
            id,
          );
          ctx.patchState({
            student: {
              ...ctx.getState().student!,
              openToBackgroundCheck: action.payload.openToBackgroundCheck,
            },
          });
        }),
      );
  }

  @Action(UpdateDrugCheckPreferences)
  updateDrugCheckPreferences(
    ctx: StateContext<UserProfileStateModel>,
    action: UpdateDrugCheckPreferences,
  ) {
    const { id, openToBackgroundCheck } = ctx.getState().student!;

    return this.studentService
      .studentIdProfileChecksPreferencesPut({
        id: id,
        body: {
          openToBackgroundCheck: openToBackgroundCheck,
          openToDrugCheck: action.payload.openToDrugCheck,
        },
      })
      .pipe(
        tap(() => {
          this.analyticsService.trackProfile(
            { openToDrugCheck: action.payload.openToDrugCheck },
            id,
          );
          ctx.patchState({
            student: {
              ...ctx.getState().student!,
              openToDrugCheck: action.payload.openToDrugCheck,
            },
          });
        }),
      );
  }

  @Action(UpdateRelocationPreference)
  updateRelocationPreference(
    ctx: StateContext<UserProfileStateModel>,
    action: UpdateRelocationPreference,
  ) {
    const { id } = ctx.getState().student!;

    return this.studentService
      .studentIdProfileRelocationPreferencePut({
        id: id,
        body: {
          openToRelocate: action.payload.openToRelocate!,
        },
      })
      .pipe(
        tap(() => {
          this.analyticsService.trackProfile(
            { openToRelocate: action.payload.openToRelocate },
            id,
          );
          ctx.patchState({
            student: {
              ...ctx.getState().student!,
              openToRelocate: action.payload.openToRelocate!,
            },
          });
        }),
      );
  }

  @Action(UpdateLocation)
  updateLocation(
    ctx: StateContext<UserProfileStateModel>,
    action: UpdateLocation,
  ) {
    const { id } = ctx.getState().profile!;

    return this.userProfileService
      .updateLocation({
        id: id,
        body: {
          googlePlaceId: action.payload.location.googlePlaceId,
        },
      })
      .pipe(
        tap((location) => {
          this.analyticsService.trackProfile(
            { state: location.state, city: location.city },
            id,
          );
          ctx.patchState({
            profile: {
              ...ctx.getState().profile!,
              location,
            },
          });
        }),
      );
  }

  @Action(UpdateEducationLevel)
  updateEducationLevel(
    ctx: StateContext<UserProfileStateModel>,
    action: UpdateEducationLevel,
  ) {
    const { id } = ctx.getState().profile!;

    return this.userProfileService
      .updateEducationLevel({
        id: id,
        body: { educationLevelId: action.payload.educationLevel.id },
      })
      .pipe(
        tap(() => {
          this.analyticsService.trackProfile(
            { educationLevel: action.payload.educationLevel.name },
            id,
          );
          ctx.patchState({
            profile: {
              ...ctx.getState().profile!,
              educationLevel: action.payload.educationLevel,
            },
          });
        }),
      );
  }

  @Action(UpdateYearsOfExperience)
  updateYearsOfExperience(
    ctx: StateContext<UserProfileStateModel>,
    action: UpdateYearsOfExperience,
  ) {
    const { id } = ctx.getState().profile!;

    return this.userProfileService
      .updateExperienceLevel({
        id: id,
        body: { experienceLevelId: action.payload.yearsOfExperience.id },
      })
      .pipe(
        tap(() => {
          this.analyticsService.trackProfile(
            { yearsOfExperience: action.payload.yearsOfExperience.name },
            id,
          );
          ctx.patchState({
            profile: {
              ...ctx.getState().profile!,
              experienceLevel: action.payload.yearsOfExperience,
            },
          });
        }),
      );
  }

  @Action(UpdateSalaryExpectations)
  updateSalaryExpectations(
    ctx: StateContext<UserProfileStateModel>,
    action: UpdateSalaryExpectations,
  ) {
    const { id } = ctx.getState().student!;

    return this.studentService
      .studentIdProfileSalaryExpectationsPut({
        id: id,
        body: {
          type: action.payload.salaryExpectations.type,
          rateType: action.payload.salaryExpectations.rateType,
          exactRate: action.payload.salaryExpectations.exactRate,
          maxRate: action.payload.salaryExpectations.maxRate,
          minRate: action.payload.salaryExpectations.minRate,
        },
      })
      .pipe(
        tap(() => {
          this.analyticsService.trackProfile({ salaryExpectations: true }, id);
          ctx.patchState({
            student: {
              ...ctx.getState().student!,
              salaryExpectations: {
                type: action.payload.salaryExpectations.type,
                rateType: action.payload.salaryExpectations.rateType,
                exactRate: action.payload.salaryExpectations.exactRate,
                maxRate: action.payload.salaryExpectations.maxRate,
                minRate: action.payload.salaryExpectations.minRate,
              },
            },
          });
        }),
      );
  }

  @Action(UpdateLanguages)
  updateLanguages(
    ctx: StateContext<UserProfileStateModel>,
    action: UpdateLanguages,
  ) {
    const { id } = ctx.getState().profile!;

    return this.userProfileService
      .updateLanguages({
        id: id,
        body: { languageIds: action.payload.languages.map((l) => l.id!) },
      })
      .pipe(
        tap(() => {
          this.analyticsService.trackProfile(
            { languages: action.payload.languages.map((l) => l.name) },
            id,
          );
          ctx.patchState({
            profile: {
              ...ctx.getState().profile!,
              languages: action.payload.languages,
            },
          });
        }),
      );
  }

  @Action(UpdateCitizenship)
  updateCitizenship(
    ctx: StateContext<UserProfileStateModel>,
    action: UpdateCitizenship,
  ) {
    const { id } = ctx.getState().profile!;

    return this.studentService
      .studentIdProfileCitizenshipPut({
        id: id,
        body: {
          countryId: action.payload.citizenship.country.id,
          authorizedToWork: action.payload.citizenship.authorizedToWork,
          visaStatusId: action.payload.citizenship.visaStatus?.id || null,
        },
      })
      .pipe(
        tap(() => {
          this.analyticsService.trackProfile(
            {
              citizenship: action.payload.citizenship.country.name,
              visaStatus: action.payload.citizenship.visaStatus?.name,
              usWorkAuthorization:
                !!action.payload.citizenship.authorizedToWork,
            },
            id,
          );
          ctx.patchState({
            student: {
              ...ctx.getState().student!,
              citizenship: {
                countryId: action.payload.citizenship.country.id,
                countryName: action.payload.citizenship.country.name,
                countryRequiresWorkAuthorization:
                  action.payload.citizenship.country.requiresWorkAuthorization,
                authorizedToWork: action.payload.citizenship.authorizedToWork,
                visaStatusId: action.payload.citizenship.visaStatus?.id || null,
                visaStatusName:
                  action.payload.citizenship.visaStatus?.name || null,
              },
            },
          });
        }),
      );
  }

  @Action(UpdatePhoneNumber)
  updatePhoneNumber(
    ctx: StateContext<UserProfileStateModel>,
    action: UpdatePhoneNumber,
  ) {
    const { id } = ctx.getState().profile!;

    return this.userProfileService
      .updatePhoneNumber({
        id: id,
        body: { phoneNumber: action.payload.phoneNumber },
      })
      .pipe(
        tap(() => {
          this.analyticsService.trackProfile({ phoneNumber: true }, id);
          ctx.patchState({
            profile: {
              ...ctx.getState().profile!,
              phoneNumber: action.payload.phoneNumber,
            },
          });
        }),
      );
  }

  @Action(UpdateBirthDate)
  updateBirthDate(
    ctx: StateContext<UserProfileStateModel>,
    action: UpdateBirthDate,
  ) {
    const { id } = ctx.getState().profile!;
    const birthDate =
      action.payload.birthDate === null
        ? null
        : action.payload.birthDate.toISOString()?.split("T")[0];

    return this.userProfileService
      .updateBirthDate({
        id: id,
        body: { birthDate: birthDate },
      })
      .pipe(
        tap(() => {
          this.analyticsService.trackProfile({ birthDate: true }, id);
          ctx.patchState({
            profile: {
              ...ctx.getState().profile!,
              birthDate: birthDate,
            },
          });
        }),
      );
  }

  @Action(UpdateStudentPhoto)
  updateStudentPhoto(
    ctx: StateContext<UserProfileStateModel>,
    action: UpdateStudentPhoto,
  ) {
    const { id } = ctx.getState().profile!;

    ctx.patchState({
      updatePhotoLoading: true,
    });

    return this.userProfileService
      .uploadPhoto({
        id: id,
        body: {
          OriginalPhoto: action.payload.originalPhoto,
          CroppedPhoto: action.payload.croppedPhoto,
        },
      })
      .pipe(
        tap((response) => {
          const currentRole = this.store.selectSnapshot(
            AuthState.user,
          )!.roleGroup;

          if (currentRole === UserGroup.Student) {
            this.store.dispatch(
              new UpdateUserPhoto({ photoUrl: response.photoUrl }),
            );
          }
        }),
        finalize(() => {
          this.analyticsService.trackProfile({ photoUploaded: true }, id);
          ctx.patchState({ updatePhotoLoading: false });
        }),
        switchMap(() => this.getUserProfileRequest(ctx, id)),
      );
  }

  @Action(UpdateEmergencyContact)
  updateEmergencyContact(
    ctx: StateContext<UserProfileStateModel>,
    action: UpdateEmergencyContact,
  ) {
    const { id } = ctx.getState().profile!;

    return this.studentService
      .studentIdProfileEmergencyContactPut({
        id: id,
        body: { info: action.payload.emergencyContactInfo },
      })
      .pipe(
        tap(() => {
          ctx.patchState({
            student: {
              ...ctx.getState().student!,
              emergencyContactInfo: action.payload.emergencyContactInfo,
            },
          });
        }),
      );
  }

  @Action(AddEducation)
  addEducation(ctx: StateContext<UserProfileStateModel>, action: AddEducation) {
    const { id } = ctx.getState().profile!;

    ctx.patchState({
      updateEducationLoading: true,
    });

    return this.userProfileService
      .addEducation({
        id: id,
        body: action.payload.education,
      })
      .pipe(
        switchMap(() => this.getUserProfileRequest(ctx, id)),
        finalize(() => {
          this.analyticsService.trackProfile({ education: true }, id);
          ctx.patchState({
            updateEducationLoading: false,
          });
        }),
      );
  }

  @Action(UpdateEducation)
  updateEducation(
    ctx: StateContext<UserProfileStateModel>,
    action: UpdateEducation,
  ) {
    const { id } = ctx.getState().profile!;

    ctx.patchState({
      updateEducationLoading: true,
    });

    return this.userProfileService
      .updateEducation({
        userProfileId: id,
        educationId: action.payload.educationId,
        body: action.payload.education,
      })
      .pipe(
        switchMap(() => this.getUserProfileRequest(ctx, id)),
        finalize(() => {
          ctx.patchState({
            updateEducationLoading: false,
          });
        }),
      );
  }

  @Action(DeleteEducation)
  deleteEducation(
    ctx: StateContext<UserProfileStateModel>,
    action: DeleteEducation,
  ) {
    const { id } = ctx.getState().profile!;

    return this.userProfileService
      .deleteEducation({
        userProfileId: id,
        educationId: action.payload.educationId,
      })
      .pipe(switchMap(() => this.getUserProfileRequest(ctx, id)));
  }

  @Action(AddExperience)
  addExperience(
    ctx: StateContext<UserProfileStateModel>,
    action: AddExperience,
  ) {
    const { id } = ctx.getState().profile!;

    ctx.patchState({
      updateExperienceLoading: true,
    });

    return this.userProfileService
      .addExperience({
        id: id,
        body: action.payload.experience,
      })
      .pipe(
        switchMap(() => this.getUserProfileRequest(ctx, id)),
        finalize(() => {
          this.analyticsService.trackProfile({ experience: true }, id);
          ctx.patchState({
            updateExperienceLoading: false,
          });
        }),
      );
  }

  @Action(UpdateExperience)
  updateExperience(
    ctx: StateContext<UserProfileStateModel>,
    action: UpdateExperience,
  ) {
    const { id } = ctx.getState().profile!;

    ctx.patchState({
      updateExperienceLoading: true,
    });

    return this.userProfileService
      .updateExperience({
        userProfileId: id,
        experienceId: action.payload.experienceId,
        body: action.payload.experience,
      })
      .pipe(
        switchMap(() => this.getUserProfileRequest(ctx, id)),
        finalize(() => {
          ctx.patchState({
            updateExperienceLoading: false,
          });
        }),
      );
  }

  @Action(DeleteExperience)
  deleteExperience(
    ctx: StateContext<UserProfileStateModel>,
    action: DeleteExperience,
  ) {
    const { id } = ctx.getState().profile!;

    return this.userProfileService
      .deleteExperience({
        userProfileId: id,
        experienceId: action.payload.experienceId,
      })
      .pipe(switchMap(() => this.getUserProfileRequest(ctx, id)));
  }

  @Action(UpdateStudentProgramPreferences)
  updateStudentProgramPreferences(
    ctx: StateContext<UserProfileStateModel>,
    action: UpdateStudentProgramPreferences,
  ) {
    const { id } = ctx.getState().profile!;

    ctx.patchState({
      updatePreferencesLoading: true,
    });

    return this.studentService
      .studentIdProfileProgramPreferencesPut({
        id: id,
        body: action.payload.preferences,
      })
      .pipe(
        finalize(() => {
          ctx.patchState({
            updatePreferencesLoading: false,
          });
        }),
      );
  }

  @Action(ResetStudentPreferences)
  resetStudentPreferences(ctx: StateContext<UserProfileStateModel>) {
    ctx.patchState({
      programPreferences: null,
    });
  }

  @Action(AddCertification)
  addCertification(
    ctx: StateContext<UserProfileStateModel>,
    action: AddCertification,
  ) {
    const { id } = ctx.getState().profile!;

    ctx.patchState({
      updateCertificationLicenseLoading: true,
    });

    return this.userProfileService
      .addCertification({
        id: id,
        body: action.payload.certification,
      })
      .pipe(
        switchMap(() => this.getUserProfileRequest(ctx, id)),
        finalize(() => {
          this.analyticsService.trackProfile({ certifications: true }, id);
          ctx.patchState({
            updateCertificationLicenseLoading: false,
          });
        }),
      );
  }

  @Action(UpdateCertification)
  updateCertification(
    ctx: StateContext<UserProfileStateModel>,
    action: UpdateCertification,
  ) {
    const { id } = ctx.getState().profile!;

    ctx.patchState({
      updateCertificationLicenseLoading: true,
    });

    return this.userProfileService
      .updateCertification({
        userProfileId: id,
        certificationId: action.payload.certificationId,
        body: action.payload.certification,
      })
      .pipe(
        switchMap(() => this.getUserProfileRequest(ctx, id)),
        finalize(() => {
          ctx.patchState({
            updateCertificationLicenseLoading: false,
          });
        }),
      );
  }

  @Action(SaveLicense)
  saveLicense(ctx: StateContext<UserProfileStateModel>, action: SaveLicense) {
    const { id } = ctx.getState().profile!;
    const { licenseId } = action.payload;

    ctx.patchState({ updateCertificationLicenseLoading: true });

    const request: Observable<unknown> = licenseId
      ? this.userProfileService.updateLicense({
          licenseId: licenseId,
          userProfileId: id,
          body: action.payload.license,
        })
      : this.userProfileService.addLicense({
          id: id,
          body: action.payload.license,
        });

    return request.pipe(
      switchMap(() => this.getUserProfileRequest(ctx, id)),
      finalize(() => {
        licenseId || this.analyticsService.trackProfile({ licenses: true }, id);
        ctx.patchState({ updateCertificationLicenseLoading: false });
      }),
    );
  }

  @Action(DeleteCertification)
  deleteCertification(
    ctx: StateContext<UserProfileStateModel>,
    action: DeleteCertification,
  ) {
    const { id } = ctx.getState().profile!;

    return this.userProfileService
      .deleteCertification({
        userProfileId: id,
        certificationId: action.payload.id,
      })
      .pipe(switchMap(() => this.getUserProfileRequest(ctx, id)));
  }

  @Action(DeleteLicense)
  deleteLicense(
    ctx: StateContext<UserProfileStateModel>,
    action: DeleteLicense,
  ) {
    const userProfileId = ctx.getState().student!.id;
    const licenseId = action.payload.id;

    return this.userProfileService
      .deleteLicense({ licenseId, userProfileId })
      .pipe(switchMap(() => this.getUserProfileRequest(ctx, userProfileId)));
  }

  private getStudentRequest(
    ctx: StateContext<UserProfileStateModel>,
    studentId: string,
  ): Observable<GetStudentStudentDetails> {
    return this.studentService.studentIdGet({ id: studentId }).pipe(
      tap((response) => {
        ctx.patchState({
          student: response,
        });
      }),
    );
  }

  private getUserProfileRequest(
    ctx: StateContext<UserProfileStateModel>,
    profileId: string,
  ): Observable<GetUserProfileUserProfileDetails> {
    return this.userProfileService.getUserProfile({ id: profileId }).pipe(
      tap((response) => {
        if (response.studentId) {
          this.getStudentRequest(ctx, response.studentId).subscribe();
        }
        ctx.patchState({
          profile: response,
        });
      }),
    );
  }
}
