import { Injectable } from "@angular/core";
import { Action, Selector, State, StateContext } from "@ngxs/store";
import { ExternshipOpportunityStep, OpportunityMode } from "./models";
import { Subject, finalize, of, takeUntil, tap } from "rxjs";
import { OpportunityService } from "@platform-app/app/core/api/services";
import {
  GetOpportunitiesOpportunity,
  OpportunityType,
} from "@platform-app/app/core/api/models";

export interface MyOpportunityStateModel {
  id?: string | null;
  currentMode?: OpportunityMode | null;
  currentStep?: ExternshipOpportunityStep | null;
  opportunityType?: OpportunityType | null;
  programList: GetOpportunitiesOpportunity[] | null;
  programsTotalAmount: number;
  currentPage: number;
  itemsPerPage: number;
  dataLoading: boolean;
  error: string | null;
}

export class SetOpportunityType {
  static readonly type = "[My Opportunity] Set Opportunity Type";
  constructor(public payload: { opportunityType: OpportunityType }) {}
}

export class SetOpportunityCurrentMode {
  static readonly type = "[My Opportunity] Set Opportunity Current Mode";
  constructor(public payload: { currentMode: OpportunityMode | null }) {}
}

export class SetOpportunityStep {
  static readonly type = "[My Opportunity] Set Opportunity Step";
  constructor(public payload: { currentStep: number }) {}
}

export class SetOpportunityNextStep {
  static readonly type = "[My Opportunity] Set Opportunity Next Step";
}

export class SetOpportunityPrevStep {
  static readonly type = "[My Opportunity] Set Opportunity Previous Step";
}

export class SetCurrentOpportunity {
  static readonly type = "[My Opportunity] Set Current Opportunity";
  constructor(
    public payload: {
      id?: string | null;
      currentMode?: OpportunityMode | null;
      currentStep?: ExternshipOpportunityStep | null;
    },
  ) {}
}

export class LoadClinicPrograms {
  static readonly type = "[My Opportunities List] Load Clinic Programs";
  constructor() {}
}

export class PageRangeChanged {
  static readonly type = "[My Opportunities List] Page Range Changed";
  constructor(public payload: { itemsPerPage: number }) {}
}

export class PageNumberChanged {
  static readonly type = "[My Opportunities List] Page Number Changed";
  constructor(public payload: { pageNumber: number }) {}
}

export class ResetOpportunitySetup {
  static readonly type = "[My Opportunities] Reset Opportunity Setup";
}

export class ResetOpportunityListPagination {
  static readonly type =
    "[My Opportunities List] Reset Opportunity List Pagination";
}

@State<MyOpportunityStateModel>({
  name: "MyOpportunity",
  defaults: {
    id: null,
    currentMode: null,
    currentStep: null,
    opportunityType: null,
    programList: null,
    programsTotalAmount: 0,
    currentPage: 1,
    itemsPerPage: 10,
    dataLoading: false,
    error: null,
  },
})
@Injectable()
export class MyOpportunityState {
  cancelPrevious$ = new Subject<void>();

  constructor(private opportunityService: OpportunityService) {}

  @Selector()
  static opportunityId(state: MyOpportunityStateModel) {
    return state.id;
  }

  @Selector()
  static dataLoading(state: MyOpportunityStateModel) {
    return state.dataLoading;
  }

  @Selector()
  static programList(state: MyOpportunityStateModel) {
    return state.programList;
  }

  @Selector()
  static programTotalAmount(state: MyOpportunityStateModel) {
    return state.programsTotalAmount;
  }

  @Selector()
  static itemsPerPage(state: MyOpportunityStateModel) {
    return state.itemsPerPage;
  }

  @Selector()
  static currentPage(state: MyOpportunityStateModel) {
    return state.currentPage;
  }

  @Selector()
  static currentStep(state: MyOpportunityStateModel) {
    return state.currentStep;
  }

  @Selector()
  static opportunityType(state: MyOpportunityStateModel) {
    return state.opportunityType;
  }

  @Selector()
  static currentMode(state: MyOpportunityStateModel) {
    return state.currentMode;
  }

  @Action(SetOpportunityType)
  setOpportunityType(
    ctx: StateContext<MyOpportunityStateModel>,
    action: SetOpportunityType,
  ) {
    ctx.patchState({
      opportunityType: action.payload.opportunityType,
    });
  }

  @Action(SetOpportunityCurrentMode)
  setOpportunityCurrentMode(
    ctx: StateContext<MyOpportunityStateModel>,
    action: SetOpportunityCurrentMode,
  ) {
    ctx.patchState({
      currentMode: action.payload.currentMode,
    });
  }

  @Action(SetOpportunityStep)
  setOpportunityStep(
    ctx: StateContext<MyOpportunityStateModel>,
    action: SetOpportunityStep,
  ) {
    ctx.patchState({
      currentStep: action.payload.currentStep,
    });
  }

  @Action(SetOpportunityNextStep)
  setOpportunityNextStep(ctx: StateContext<MyOpportunityStateModel>) {
    const currentStep = ctx.getState().currentStep;

    ctx.patchState({
      currentStep: currentStep ? currentStep + 1 : currentStep,
    });
  }

  @Action(SetOpportunityPrevStep)
  setOpportunityPrevStep(ctx: StateContext<MyOpportunityStateModel>) {
    const currentStep = ctx.getState().currentStep;

    ctx.patchState({
      currentStep: currentStep ? currentStep - 1 : currentStep,
    });
  }

  @Action(SetCurrentOpportunity)
  setCurrentOpportunity(
    ctx: StateContext<MyOpportunityStateModel>,
    action: SetCurrentOpportunity,
  ) {
    const stateSnapshot = ctx.getState();
    const {
      id = stateSnapshot.id,
      currentMode = stateSnapshot.currentMode,
      currentStep = stateSnapshot.currentStep,
    } = action.payload;

    ctx.patchState({
      id,
      currentMode,
      currentStep,
    });
  }

  @Action(ResetOpportunitySetup)
  resetOpportunitySetup(ctx: StateContext<MyOpportunityStateModel>) {
    ctx.patchState({
      id: null,
      currentMode: null,
      currentStep: null,
    });
  }

  @Action(LoadClinicPrograms)
  loadPrograms(ctx: StateContext<MyOpportunityStateModel>) {
    return this.getPrograms(ctx);
  }

  @Action(PageRangeChanged)
  pageRangeChanged(
    ctx: StateContext<MyOpportunityStateModel>,
    action: PageRangeChanged,
  ) {
    ctx.patchState({
      itemsPerPage: action.payload.itemsPerPage,
      currentPage: 1,
    });

    return this.getPrograms(ctx);
  }

  @Action(PageNumberChanged)
  pageNumberChanged(
    ctx: StateContext<MyOpportunityStateModel>,
    action: PageNumberChanged,
  ) {
    ctx.patchState({
      currentPage: action.payload.pageNumber,
    });

    return this.getPrograms(ctx);
  }

  @Action(ResetOpportunityListPagination)
  resetOpportunityList(ctx: StateContext<MyOpportunityStateModel>) {
    ctx.patchState({
      currentPage: 1,
      itemsPerPage: 10,
      programList: null,
      programsTotalAmount: 0,
    });
  }

  private getPrograms(ctx: StateContext<MyOpportunityStateModel>) {
    const stateSnapshot = ctx.getState();
    this.cancelPrevious$.next();

    if (!stateSnapshot.programList) {
      ctx.patchState({
        dataLoading: true,
      });
    }

    return this.opportunityService
      .opportunityOpportunitiesPost({
        body: {
          pagination: {
            page: stateSnapshot.currentPage,
            itemsPerPage: stateSnapshot.itemsPerPage,
          },
        },
      })
      .pipe(
        tap((response) => {
          ctx.patchState({
            programList: response.opportunities,
            programsTotalAmount: response.totalAmount,
          });
          return of(response);
        }),
        takeUntil(this.cancelPrevious$),
        finalize(() =>
          ctx.patchState({
            dataLoading: false,
          }),
        ),
      );
  }
}
