import { OrganizationMember } from "@platform-app/app/core/api/models/organization-member";
import { Action, Selector, State, StateContext, Store } from "@ngxs/store";
import { Injectable } from "@angular/core";
import { OrganizationService } from "@platform-app/app/core/api/services/organization.service";
import { finalize, switchMap, tap } from "rxjs";
import { CreateOrganizationInvitationOrganizationInvitation } from "@platform-app/app/core/api/models/create-organization-invitation-organization-invitation";
import { OrganizationInvitationUpdates } from "@platform-app/app/core/api/models/organization-invitation-updates";
import {
  GetTeamsForInvitationTeam,
  OrganizationStaffMemberUpdates,
} from "@platform-app/app/core/api/models";
import { TeamsService } from "@platform-app/app/core/api/services";
import { AuthState } from "@platform-app/app/core/auth/auth.state";

export interface OrganizationUsersStateModel {
  users: OrganizationMember[];
  owners: GetTeamsForInvitationTeam[];
  defaultOwnerId: string | null;
  isLoading: boolean;
}

export class GetOrganizationUsers {
  static readonly type = "[Organization Users] Get Users";
}

export class CreateInvitation {
  static readonly type = "[Organization Users] Create Invitation";
  constructor(
    public payload: {
      body: CreateOrganizationInvitationOrganizationInvitation;
    },
  ) {}
}

export class UpdateInvitation {
  static readonly type = "[Organization Users] Update Invitation";
  constructor(
    public payload: {
      body: OrganizationInvitationUpdates;
      invitationId: string;
    },
  ) {}
}

export class GetOwners {
  static readonly type = "[Organization Users] Get Owners";
}

export class UpdateOrganizationMember {
  static readonly type = "[Organization Users] Update Organization Member";
  constructor(
    public payload: {
      body: OrganizationStaffMemberUpdates;
      userId: string;
    },
  ) {}
}

@State<OrganizationUsersStateModel>({
  name: "OrganizationUsers",
  defaults: {
    users: [],
    owners: [],
    defaultOwnerId: null,
    isLoading: false,
  },
})
@Injectable()
export class OrganizationUsersState {
  constructor(
    private readonly organizationService: OrganizationService,
    private readonly teamsService: TeamsService,
    private readonly store: Store,
  ) {}

  @Selector()
  static isLoading(state: OrganizationUsersStateModel) {
    return state.isLoading;
  }

  @Selector()
  static users(state: OrganizationUsersStateModel) {
    return state.users;
  }

  @Selector()
  static owners(state: OrganizationUsersStateModel) {
    return state.owners;
  }

  @Selector()
  static defaultOwnerId(state: OrganizationUsersStateModel) {
    return state.defaultOwnerId;
  }

  @Action(GetOrganizationUsers)
  getOrganizationUsers(ctx: StateContext<OrganizationUsersStateModel>) {
    ctx.patchState({ isLoading: true, users: [] });

    return this.fetchOrganizationUsers(ctx);
  }

  @Action(CreateInvitation)
  createInvitation(
    ctx: StateContext<OrganizationUsersStateModel>,
    action: CreateInvitation,
  ) {
    return this.organizationService
      .organizationMembersInvitePost({ ...action.payload })
      .pipe(switchMap(() => this.fetchOrganizationUsers(ctx)));
  }

  @Action(UpdateInvitation)
  updateInvitation(
    ctx: StateContext<OrganizationUsersStateModel>,
    action: UpdateInvitation,
  ) {
    return this.organizationService
      .updateOrganizationInvitation({ ...action.payload })
      .pipe(switchMap(() => this.fetchOrganizationUsers(ctx)));
  }

  @Action(GetOwners)
  getOwners(ctx: StateContext<OrganizationUsersStateModel>) {
    return this.teamsService.getTeamsForInvitation().pipe(
      tap(({ teams, defaultTeamId }) =>
        ctx.patchState({
          owners: teams,
          defaultOwnerId: defaultTeamId,
        }),
      ),
    );
  }

  @Action(UpdateOrganizationMember)
  updateOrganizationMember(
    ctx: StateContext<OrganizationUsersStateModel>,
    action: UpdateOrganizationMember,
  ) {
    return this.organizationService
      .updateOrganizationStaffMember({ ...action.payload })
      .pipe(switchMap(() => this.fetchOrganizationUsers(ctx)));
  }

  private fetchOrganizationUsers(
    ctx: StateContext<OrganizationUsersStateModel>,
  ) {
    const organizationId = this.store.selectSnapshot(AuthState.user)
      ?.organization?.id;

    if (!organizationId) {
      throw new Error("Current User does not have Organization id");
    }

    return this.organizationService
      .getOrganizationMembers({ organizationId })
      .pipe(
        tap((users) =>
          ctx.patchState({
            users,
          }),
        ),
        finalize(() => ctx.patchState({ isLoading: false })),
      );
  }
}
